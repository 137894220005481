@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Teko:300,500,600');
@import url('https://fonts.googleapis.com/css?family=Khand');
@import url('https://fonts.googleapis.com/css?family=Teko:300,500,600');

@font-face {
    font-family: 'Big noodle';
    src: url(fonts/big_noodle_titling_oblique.ttf) format('truetype'); }

* {
    font-family: 'Roboto Condensed', sans-serif; }
/* You can add global styles to this file, and also import other style files */

//.fa
//    font: normal normal normal 14px/1 FontAwesome!important

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(fonts/MaterialIcons-Regular.eot);
    src: local('Material Icons');
    src: local('MaterialIcons-Regular');
    src: url(fonts/MaterialIcons-Regular.woff2) format('woff2');
    src: url(fonts/MaterialIcons-Regular.woff) format('woff');
    src: url(fonts/MaterialIcons-Regular.ttf) format('truetype'); }


@font-face {
    font-family: 'RexBoldInlineRegular';
    src: url(fonts/rex-bold-inline-webfont.ttf) format('truetype'); }

@font-face {
    font-family: 'rex_bold_inlineregular';
    src: url(fonts/rex-bold-inline-webfont.woff2) format('woff2');
    src: url(fonts/rex-bold-inline-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal; }


.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga'; }

