@import '~@angular/material/_theming';
@import 'theme';

$primary-palette: mat-palette($md-power-yellow, 500);
$accent-palette: mat-palette($md-power-blue, 500);
$greener-palette: mat-palette($md-power-green, 500);
$warn-palette: mat-palette($mat-deep-orange, 500);
$white-palette: mat-palette($mat-grey, A100);

$primary: mat-color($primary-palette);
$accent: mat-color($accent-palette);
$greener: mat-color($greener-palette);
$warn: mat-color($warn-palette);
$gray: rgba(0, 0, 0, 0.12);

.accent-color {
    color: $accent; }

.warn-color {
    color: $warn; }

$colorWhite: mat-color($white-palette);
$colorBlack: #141414;
$colorGrey: #5b5f64;
$colorGreyLight: #f2f4f5;
$colorPowerGrey: #424242;

// Colors global optimization when starting theme
$colorMenu: $colorGrey;
$colorActivies: $primary;
$colorPackagesBg: $colorWhite;
$colorPackagesCardHover: $accent;
$colorFooterButton: $primary;
$colorFooterBg: $accent;
